<template>
	<div class="workbench">
		<div class="page1" v-if="page">
			<el-calendar v-model="value">
				<template
					slot="dateCell"
					slot-scope="{date, data}" @click="chooseDay(data)">
					<div class="tr">{{data.day.split('-')[1]}} 月 {{data.day.split('-')[2]}} 日</div>
					<div v-if="dealMyCssDate(data)" style="height: 100px;" @click="activeClick(data)">
						<ul>
							<li :class="dealMyCssDate(data) ? 'item fs12 cr_fff pl10 pr10' : ''"
								v-for="w in dealMyDate(data)" :key="w.index">{{w}}</li>
							<!--						<li class="item fs12 cr_fff pl10 pr10" @click="activeConfirm">点击新增确认</li>-->
						</ul>
						<div style="position: relative" v-if="dealMyCountDate(data)">
							<div class="restsession"> 共计( <span style="color: rgb(254, 68, 71);">{{dealMyCountDate(data)}}</span>)场 </div>
						</div>
					</div>
					<div v-else style="height: 100px;" @click="unActiveClick(data)">
					</div>
				</template>
			</el-calendar>
			<div class="operation">
				<el-button type="danger" @click="$router.push('/addActivity')">新建活动</el-button>
				<el-button @click="pqView">商品排期</el-button>
			</div>
		</div>
		<div class="page2" v-else>
			<el-button @click="page = !page" class="mb20">返回日历</el-button>
			<div class="hand_card">
				<el-tabs v-model="activeName" @tab-click="handleClick">
					<el-tab-pane label="待排期" name="dpq"></el-tab-pane>
					<el-tab-pane label="待直播" name="dzb"></el-tab-pane>
				</el-tabs>
				<el-form :inline="true" ref="form" :model="form" label-width="auto" size="mini">
					<el-form-item label="商品名称">
						<el-input v-model="form.name" placeholder="请输入商品名称"></el-input>
					</el-form-item>
					<el-form-item label="店铺名称">
						<el-input v-model="form.shop_name" placeholder="请输入店铺名称"></el-input>
					</el-form-item>
					<el-form-item label="商品分类">
						<el-select v-model="form.cat" placeholder="商品分类">
							<el-option label="全部" value=""></el-option>
							<el-option :label="cate.name" :value="cate.id"  v-for="cate in cateLists" :key="cate.index"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="排期日期">
						<el-date-picker
							v-model="form.pqtime"
							type="daterange"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期">
							</el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button @click="resetSearch()">重置</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="searchGoods()">查询</el-button>
					</el-form-item>
<!--					<el-form-item>-->
<!--						<el-button>导出</el-button>-->
<!--					</el-form-item>-->
<!--					<el-form-item v-if="activeName === '待审核' || activeName === '待复审' || activeName === '待终审'">-->
<!--						<el-button>批量审核</el-button>-->
<!--					</el-form-item>-->
				</el-form>
				<div class="table_title flex_r bg_table mb10">
					<span>审核状态</span>
					<span>商品信息</span>
					<span>价格</span>
					<span>优惠</span>
					<span>福利</span>
					<span>试样记录</span>
					<span>操作</span>
				</div>
				<div class="br_1_eee" v-for="item in goodsLists" v-bind:key="item">
					<div class="item_title flex_r f_ai_c f_jc_b tc bg_table pl20 pr20 fs12">
						<div>
							<el-checkbox></el-checkbox>
							<span class="pl20 pr20">流水号：</span>
							<span>商品来源：商家报名</span>
						</div>
						<div>
							<img class="mr10" width="16" height="16" src="//img.alicdn.com/imgextra//cb/34/TB1nMIPiqL7gK0jSZFBwu0ZZpXa.png" alt="">
							<span>{{ item.nick }}</span>
						</div>
<!--						<div class="flex_c f_jc_c f_ai_c" @click="flagHandle">-->
<!--							<i class="el-icon-s-flag fs16 cr_eee"></i>-->
<!--							<span>标记</span>-->
<!--						</div>-->
					</div>
					<ul class="item_ul flex_r f_ai_c f_jc_b fs12">
						<li class="br_r_1_eee flex_c f_jc_b">
							<el-select class="" v-model="item.statusInfo">
								<el-option :label="item.statusInfo" :value="item.statusInfo"></el-option>
							</el-select>
							<div>
								<span>排期：</span>
								<el-date-picker
									style="width:150px"
									class="mt10"
									v-model="item.zbtime"
									@change="dataSelect($event,item)"
									type="date"
									placeholder="选择排期">
								</el-date-picker>
							</div>
							<span v-if="item.act_id">活动：{{ item.a_title }}</span>
							<span v-else>活动：-</span>
						</li>
						<li class="br_r_1_eee flex_r">
							<el-popover
								trigger="hover">
								<img height="300" width="300" :src="item.pict_url" alt="">
								<img slot="reference" height="80" width="80" :src="item.pict_url" alt="">
							</el-popover>
							<div class="pl10 pr10">
								<el-link class="lh_12 ellipsis_2" type="primary">{{ item.title }}</el-link>
								<el-select class="mt10" v-model="item.cid">
									<el-option :label="item.name" :value="item.cid"></el-option>
<!--									<el-option :label="cid.name" :value="cid.id"  v-for="cid in cateLists" v-bind:key="cid"></el-option>-->
								</el-select>
								<div class="pt10">
									<span>规格：</span>
									<el-input type="textarea" :value="item.skuItem"></el-input>
								</div>
							</div>
						</li>
						<li class="br_r_1_eee flex_c f_jc_b">
							<el-input placeholder="请输入直播价" disabled v-model="item.zb_price">
								<template slot="prepend">直播价</template>
							</el-input>
							<el-input placeholder="请输入在售价" disabled v-model="item.s_price">
								<template slot="prepend">在售价</template>
							</el-input>
							<el-input placeholder="请输入在售价" v-model="item.old_price">
								<template slot="prepend">历史最低价</template>
							</el-input>
						</li>
						<li class="br_r_1_eee flex_c f_jc_b">
							<div>
								<span class="cr_999">原价(划线价)：</span>
								<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
							</div>
							<div>
								<span class="cr_999">在售价：</span>
								<span>¥{{ item.s_price }}~{{ item.e_price }}</span>
							</div>
							<div v-if="item.yh_type == 1">
								<span class="cr_999">-无：</span>
							</div>
							<div v-if="item.yh_type == 2">
								<span class="cr_999">-拍下立减：</span>
								<span>¥{{item.lj}}</span>
							</div>
							<div v-if="item.yh_type == 3">
								<span class="cr_999">-领券立减：</span>
								<span>¥{{item.lq_lj}}</span>
							</div>
							<div v-if="item.yh_type == 4">
								<span class="cr_999">-联系客服领取：</span>
								<span>¥{{item.kf_lj}}</span>
							</div>
							<div v-if="item.yh_type == 5">
								<span class="cr_999">-其他优惠：</span>
							</div>
							<div v-if="item.yh_type == 6">
								<span class="cr_999">-直接改价：</span>
							</div>
							<div>
								<span class="cr_999">=直播到手价：</span>
								<span>¥{{ item.zb_price }}~{{ item.zb_e_price }}</span>
							</div>
							<div>
								<span class="cr_999">历史最低价：</span>
								<span class="el-icon-s-data cr_ff0"></span>
							</div>
						</li>
						<li class="br_r_1_eee flex_c f_jc_b">
							<el-input :rows="3" type="textarea" placeholder="赠品：" :value="item.zp"></el-input>
							<el-input :rows="3" type="textarea" placeholder="抽奖：" :value="item.zbj_cj"></el-input>
						</li>
						<li class="br_r_1_eee">
							<el-input :rows="6" type="textarea" placeholder=""></el-input>
						</li>
						<li class="br_r_1_eee flex_c f_jc_a f_ai_c">
							<span class="cur_p" @click="$refs.dialogDataRef.handleOpen()">查看数据</span>
							<router-link tag="a" target="_blank" :to="{path:'/goodsDetail', query:{id:item.id}}">报名详情</router-link>
							<router-link tag="a" target="_blank" :to="{path:'/handCardDetail', query:{id:item.id}}">查看手卡</router-link>
						</li>
					</ul>
					<ul v-if="toggle" class="br_t_1_eee pl40 pr40 pt10 tc">
						<li class="toggle flex_r f_jc_b lh12">
							<span>合同状态</span>
							<span>服务费</span>
							<span>保证金</span>
						</li>
						<li  class="toggle flex_r f_jc_b">
							<div v-if="item.c_id">
								<span v-if="item.c_status == 1">合同： 待签约</span>
								<span v-else-if="item.c_status == 2">合同： 已签约</span>
								<span v-else-if="item.c_status == 3">合同： 作废</span>
							</div>
							<div @click="ht(item)" v-else>
								<span class="pr10">待发起</span>
								<el-link class="" type="primary">发起</el-link>
							</div>
							<div v-if="item.f_id">
								<span v-if="item.f_status == 1">服务费： 待付款</span>
								<span v-else-if="item.f_status == 2">服务费： 已付款</span>
								<span v-else-if="item.f_status == 3">服务费： 已到账</span>
								<span v-else-if="item.f_status == 4">服务费： 已退还</span>
							</div>
							<div @click="fwf(item)" v-else>
								<span class="pr10">待发起</span>
								<el-link class="" type="primary">发起</el-link>
							</div>
							<div v-if="item.e_id">
								<span v-if="item.e_status == 1">保证金： 待付款</span>
								<span v-else-if="item.e_status == 2">保证金： 已付款</span>
								<span v-else-if="item.e_status == 3">保证金： 已到账</span>
								<span v-else-if="item.e_status == 4">保证金： 已退还</span>
							</div>
							<div @click="bzj(item)" v-else>
								<span class="pr10">待发起</span>
								<el-link class="" type="primary">发起</el-link>
							</div>
						</li>
					</ul>
					<div class="item_title bg_fff flex_r f_ai_c f_jc_c tc pl20 pr20 fs12 br_t_1_eee br_b_1_eee cr_999 cur_p">
						<div @click="toggle = !toggle">
							<i :class="toggle ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></i>
							<span>{{toggle ? '收起' : '展开'}}</span>
						</div>
					</div>
				</div>
				<el-pagination
						class="tr"
						background
						:small="false"
						@current-change="handleCurrentChange"
						:current-page.sync="currentPage"
						:page-size="pageSize"
						:total="total">
				</el-pagination>
				<e-dialog :title="dialogTitle" ref="dialogRef" v-on:confirm="qyConfirm">
					<el-form v-if="flag" label-width="auto" >
						<el-form-item label="标记">
							<el-radio-group v-model="radio">
								<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_eee"></i></el-radio>
								<el-radio :label="3"><i class="el-icon-s-flag fs18 cr_ff0"></i></el-radio>
								<el-radio :label="6"><i class="el-icon-s-flag fs18 cr_f18"></i></el-radio>
								<el-radio :label="9"><i class="el-icon-s-flag fs18 cr_18a"></i></el-radio>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="标记信息">
							<el-input type="textarea"></el-input>
						</el-form-item>
					</el-form>
					<e-form ref="formRef" v-on:selectChange="selectChange"  v-else :formConfList="formConfListItem" />
				</e-dialog>
				<e-dialog width="1000px" title="查看数据" ref="dialogDataRef">
					<Empty />
				</e-dialog>
				<e-dialog width="1000px" title="加入排期" ref="dialogDataAddRef">
					<el-form label-width="auto">
						<el-form-item label="直播日期">
							<el-date-picker
								v-model="value1"
								type="date"
								placeholder="选择日期">
								</el-date-picker>
						</el-form-item>
						<el-form-item label="选择排期">
							<e-table :tableCols="addScheduleTableConf" :showSelectionCol="true" :dataOrigin="dataOrigin"></e-table>
						</el-form-item>
					</el-form>
				</e-dialog>
			</div>
		</div>
		<e-dialog width="1000px" :title="date" ref="dialogActiveRef" v-on:confirm="confirm">
			<e-table ref="tables" :showPagination="false" :tableCols="ActiveTableConf" :showSelectionCol="false" :dataOrigin="dataOrigin">
				<template #21="scope">
					<div :span="24" class="flex_c f_jc_c f_ai_c">
						<div class="org">
							<img width="80" height="80" :src="scope.rowData.avatar" alt="">
							<span>{{scope.rowData.username}}</span>
						</div>
					</div>
				</template>
				<template #27="scope">
					<div :span="24">
						<router-link tag="a" target="_blank" :to="{path:'/liveDetail',query:{id:scope.rowData.id}}">查看详情</router-link>
<!--						<el-link class="ml20 fs14">删除</el-link>-->
					</div>
				</template>
			</e-table>
		</e-dialog>
	</div>
</template>

<script>
import {formConfList, addScheduleTableConf, ActiveTableConf} from '@data/handCard'
export default {
	name: 'Workbench',
	data() {
		return {
			page: true,
			value: new Date(),
			form: {},
			formConfList,
			ActiveTableConf,
			formConfListItem: [],
			dataOrigin: {
				data: []
			},
			currentPage: 1,
			pageSize: 10,
			total: 0,
			toggle: false,
			dialogTitle: '',
			flag: false,
			addScheduleTableConf,
			date: '',
			workLists: [],
			activeName: 'dpq',
			goodsLists: [],
			cateLists: []
		}
	},
	methods: {
		resetSearch(){
			this.form = {}
			this.currentPage = 1
			this.GetEntrLists()
		},
		searchGoods() {
			this.currentPage = 1
			this.GetEntrLists()
		},
		selectChange(e){
			console.log(e)
			let addressLists = this.addressLists
			let len = addressLists.length
			for(let i=0;i<len;i++){
				// console.log(response.data.data[i])
				if(e == addressLists[i].id){
					this.$refs.formRef.formData.username = addressLists[i].username
					this.$refs.formRef.formData.address = addressLists[i].name
					this.$refs.formRef.formData.mobile = addressLists[i].mobile
				}
			}
		},
		handleCurrentChange(val){
			this.currentPage = val
			this.GetEntrLists()
		},
		pqView(){
			this.page = false
			this.GetEntrLists()
		},
		qyConfirm(){
			let that = this
			let formData = this.$refs.formRef.formData
			switch (this.conType) {
				case "ht":

					if(formData.contract == '' || formData.contract == undefined){
						this.$message.error("请选择合同");
						return
					}
					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.bzj == '' || formData.bzj == undefined){
						this.$message.error("请填写保证金");
						return
					}
					if(formData.service == '' || formData.service == undefined){
						this.$message.error("请填写服务费");
						return
					}
					this.post('?server=live.contract.push.handle.post',{goods_id:this.conItem.id,contract_id:formData.contract,pay_id:formData.pay,bzj:formData.bzj,fwf:formData.service}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "fwf":

					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.fwf == '' || formData.fwf == undefined){
						this.$message.error("请填写服务费");
						return
					}
					this.post('?server=live.service.fee.push.handle.post',{goods_id:this.conItem.id,pay_id:formData.pay,fwf:formData.fwf}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
				case "bzj":

					if(formData.pay == '' || formData.pay == undefined){
						this.$message.error("请选择收款账户");
						return
					}
					if(formData.bzj == '' || formData.bzj == undefined){
						this.$message.error("请填写保证金");
						return
					}
					this.post('?server=live.bzj.fee.push.handle.post',{goods_id:this.conItem.id,pay_id:formData.pay,bzj:formData.bzj}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.$message.success(response.data.msg);
						console.log(response)
						that.$refs.formRef.formData = {}
						that.$refs.dialogRef.handleClose()
						that.GetEntrLists()
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break;
			}
		},
		dataSelect (e,value){
			// console.log(value)
			let that = this
			if(e == ''){
				this.$message.error("请选择排期时间");
				return
			}
			this.post('?server=live.schedule.push.handle.post',{goods_id:value.id,date:e}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				that.$message.success(response.data.msg);
				that.GetEntrLists()
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetEntrLists(){
			let that = this
			this.goodsLists = []
			this.get('?server=live.entr.goods.lists.get',{type:this.activeName,
				page:this.currentPage,
				query:this.form.name,
				shop_name:this.form.shop_name,
				cat:this.form.cat,
				baotime:this.form.baotime,pqtime:this.form.pqtime}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response)
				that.goodsLists = response.data.lists.data
				that.total = response.data.lists.total
				that.cateLists = response.data.category
				console.log(that.goodsLists)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		ht(e){
			console.log(e)
			this.conType = "ht"
			this.conItem = e
			this.sponsorHandle(1)

		},
		bzj(e){
			console.log(e)
			this.conType = "bzj"
			this.conItem = e
			this.sponsorHandle(3)
		},
		fwf(e){
			console.log(e)
			this.conType = "fwf"
			this.conItem = e
			this.sponsorHandle(2)
		},
		handleClick(){
			this.currentPage = 1
			this.form = {}
			this.GetEntrLists()
		},
		confirm(){
			this.$refs.dialogActiveRef.handleClose();
		},
		chooseDay(data){
			if(data.type === "current-month"){
				this.calendarStart = data.day;
				this.calendarEnd = data.day;
				this.tableInit(this.recordID.id);
			}else if(data.type === "prev-month"){
				this.prevBtn.click();
			}else if(data.type === "next-month"){
				this.nextBtn.click();
			}
		},
		dealMyCountDate(v){
			let len = this.workLists.length
			let res = 0
			for(let i=0; i<len; i++){
				if(this.workLists[i].zbdate == v.day) {
					res += 1;
					continue
				}
			}
			return res
		},
		dealMyCssDate(v){
			let len = this.workLists.length
			let res = false
			for(let i=0; i<len; i++){
				if(this.workLists[i].zbdate == v.day) {
					res = true;
					continue
				}
			}
			return res
		},
		dealMyDate(v) {
			// console.log(v)
			let len = this.workLists.length
			let res = []
			for(let i=0; i<len; i++){
				console.log(this.workLists[i].zbdate)
				if(this.workLists[i].zbdate == v.day) {
					if(res.length > 2){
						console.log(v.day)
						continue;
					}
					res.push(this.workLists[i].zb_name);
					continue
				}
			}
			return res
		},
		dialogOpen() {
			this.$refs.dialogRef.handleOpen();
		},
		flagHandle() {
			this.flag = true;
			this.dialogTitle = '标记';
			this.dialogOpen();
		},
		sponsorHandle(val) {
			let that = this
			console.log(that.$refs.formRef)
			switch(val) {
				case 1:
					this.dialogTitle = '通知商家签约';
					this.get('?server=live.contract.start.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '合同模板',
								prop: 'contract',
								type: 'select',
								options: response.data.contract
							},
							{
								label: '保证金',
								prop: 'bzj',
								type: 'input'
							},
							{
								label: '服务费',
								prop: 'service',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.bzj = response.data.bzj
						that.$refs.formRef.formData.service = response.data.service
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 3:
					this.dialogTitle = '新增保证金';
					this.get('?server=live.bzj.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '保证金金额',
								prop: 'bzj',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.bzj = response.data.bzj
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
				case 2:
					this.dialogTitle = '新增服务费';
					this.get('?server=live.fwf.info.get',{id:this.conItem.id,cid:this.conItem.cid}).
					then(function (response) {
						//这里只会在接口是成功状态返回
						//联网成功的回调
						that.formData = response.data
						that.formConfListItem = [
							{
								label: '服务费金额',
								prop: 'fwf',
								type: 'input'
							},
							{
								label: '收款信息',
								prop: 'pay',
								type: 'select',
								options: response.data.pay
							}
						];
						that.$refs.formRef.formData.fwf = response.data.service
					}).catch(function (error) {
						//这里只会在接口是失败状态返回，不需要去处理错误提示
						console.log(error);
					});
					break
			}
			this.flag = false;
			this.formConfListItem = this.formConfList[val];
			this.dialogOpen();
		},
		unActiveClick(data){
			this.$confirm('当天暂无活动信息，是否创建新的活动?', '创建活动').then(flag => {
				if(flag) {
					this.$router.push({path:'/addActivity',query:{zbtime:data.day}})
				}
			})
		},
		activeClick(data) {
			this.date = data.day;
			this.GetZbTimeLists(this.date)
			this.$refs.dialogActiveRef.handleOpen();
			this.$refs.tables.loading = true
		},
		activeConfirm() {
			this.$confirm('当天暂无活动信息，是否创建新的活动?', '创建活动').then(flag => {
				if(flag) {
					this.$router.push('/addActivity')
				}
			})
		},
		GetZbTimeLists(data){
			let that = this
			this.dataOrigin.data = []
			this.$refs.tables && this.$refs.tables.initData()
			// console.log(that.dataOrigin)
			this.get('?server=live.zb.time.lists.by.day.get',{zbtime:data}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				let len = response.data.data.length
				// console.log(len)
				for(let i=0;i<len;i++){
					// console.log(response.data.data[i])
					that.dataOrigin.data.push(response.data.data[i])
				}
				that.$refs.tables.loading = false
				// that.dataOrigin.data = response.data.data
				that.total = response.data.total
				that.$refs.tables.initData()
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		GetZbLists(){
			let that = this
			that.workLists = []
			let month = this.value.getMonth()
			// console.log(that.dataOrigin)
			this.get('?server=live.zb.time.get.group.by.month',{
				start_date: '',
				end_date: '',
				month: month+1
			}).
			then(function (response) {
				//这里只会在接口是成功状态返回
				//联网成功的回调
				// console.log(response.data.total)
				console.log(response)
				that.workLists = response.data
				// console.log(that.total)
			}).catch(function (error) {
				//这里只会在接口是失败状态返回，不需要去处理错误提示
				console.log(error);
			});
		},
		calendarListener(){
			this.$nextTick(() => {
				// 点击前一个月
				let prevBtn = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(1)"
				);
				prevBtn.addEventListener("click", () => {
					this.GetZbLists()
					// this.$notify({
					// 	title: "上一月",
					// 	message: "上个月头天：" + this.value,
					// 	type: "success",
					// 	position: "top-left"
					// });
				});

				//点击下一个月
				let nextBtn = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(3)"
				);
				nextBtn.addEventListener("click", () => {
					this.GetZbLists()
					// console.log(this.value);
					// this.$notify({
					// 	title: "下一月",
					// 	message: "下个月头天：" + this.value,
					// 	type: "warning",
					// 	position: "top-left"
					// });
				});

				//点击今天
				let todayBtn = document.querySelector(
						".el-calendar__button-group .el-button-group>button:nth-child(2)"
				);
				todayBtn.addEventListener("click", () => {
					this.GetZbLists()
					// this.$notify.info({
					// 	title: "今天",
					// 	message: "今天：" + this.value,
					// 	position: "top-left"
					// });
				});
			});

			// //修改“今天”为“当月”
			// let span1 = document.querySelector(".el-button--mini:not(:first-child):not(:last-child) span");
			// span1.innerText = '当月';
			//
			// let that = this;
			// let newHandle = function (e) {
			// 	e.stopPropagation();
			// 	that.GetZbLists();
			// };
			// //上个月按钮
			// this.prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)');
			// this.prevBtn.onclick = function (e) {
			// 	newHandle(e);
			// 	console.log(this.value)
			// };
			// //下个月按钮
			// this.nextBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(3)');
			// this.nextBtn.onclick = function (e) {
			// 	newHandle(e);
			// 	console.log(this.value)
			// };
			// //本月按钮
			// this.thisBtn = document.querySelector('.el-button--mini:not(:first-child):not(:last-child)');
			// this.thisBtn.onclick = function (e) {
			// 	newHandle(e);
			// }
		},
	},
	created() {
		this.$nextTick(() => {
			this.GetZbLists()
			this.calendarListener()
		});
	}
}
</script>

<style lang="scss">
	.el-calendar-table .el-calendar-day {
		height: 140px;
	}

	.el-calendar-table{
		width: 100%;
		height: 100%;
		&:not(.is-range){
			//使不是本月的日期不可点击，不会跳转到其他月份
			td.next{
				pointer-events: none;
			}
			td.prev{
				pointer-events: none;
			}
			//td{
			//    pointer-events: none;
			//}
		}
	}
</style>

<style lang="scss" scoped>
	.restsession{
		position: absolute;
		bottom: -32px;
		right: 5px;
		font-size: 12px;
		color: #afafaf;
	}
	.nowActive {
		border: 2px solid #ffbcc1!important;
		background: rgba(255,237,237,.16);
	}
.workbench {
	position: relative;
	.operation {
		position: absolute;
		right: 20px;
		top: 10px;
	}
	.item {
		height: 20px;
		line-height: 20px;
		margin-top: 6px;
		background-image: linear-gradient(to right, #fd4545, rgb(252, 132, 53));
	}
}
.w140 {
	width: 140px;	
}
.table_title {
	span {
		line-height: 40px;
		width: 15%;
		text-align: center;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.item_title {
	line-height: 30px;
}
.item_ul {
	li {
		width: 15%;
		padding: 10px;
		height: 120px;
		&:nth-child(2) {
			width: 22%;
		}
		&:nth-child(3) {
			width: 28%;
		}
	}
}
.toggle {
	span,
	div {
		width: 20%;
		line-height: 2;
	}
	&:nth-child(1) {
		background: #fff9f8;
	}
}
.org {
	position: relative;
	span {
		position: absolute;
		bottom: 8px;
		left: 0;
		right: 0;
		line-height: 20px;
		font-weight: bolder;
		text-align: center;
		color: #fff;
		background-color: rgba($color: #000000, $alpha: .2);
	}
}
</style>

<style lang="scss">
.workbench {
	.el-calendar__button-group {
		padding-right: 200px;
	}
}
</style>